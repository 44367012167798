.card-shadow {
  box-shadow: 1px 1px 14px 4px #eee;
}

.card-border-radius {
  border-radius: 7px;
}

.top-right-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
}