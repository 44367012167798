@import './components/variables';
@import './components/spacing';
@import './components/form';
@import './components/cards';
@import './components/images';
@import './components/typography';
@import './components/buttons';

body {
  background-color: #f2f2f2;
}

.row {
  width: 100%;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.h-100vh {
  height: 100vh;
}

.h-50px {
  height: 50px;
}

.hover-effect {
  &:hover {
    opacity: .80;
  }
}