.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: $danger;
  padding-left: 0.25rem;
  font-weight: bold;
}

.form-label {
  margin-bottom: 0;
  font-size: 12px;
  @extend .text-muted;
}